import Breadcrumb from '../components/Breadcrumb';
import Project from '../components/Project';
import './Home.css';

const Home = (props) => {
    return(
        <>
          <Breadcrumb/>
          <div className='container'>
            <div className='row'>
              {
                props.projectData.map(
                  projectCard => (
                    <Project 
                      key={projectCard.id}
                      id={projectCard.id}
                      img={projectCard.img}
                      title={projectCard.title}
                      link={projectCard.link}
                    ></Project>
                  )
                )
              }
            </div>
          </div>
        </>
    );
}

export default Home;