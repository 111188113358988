import './Breadcrumb.css';

const Breadcrumb = () => {
    return(
        <>
            <section className="breadcrumb">
                <div className="breadcrumb-lines breadcrumb-left"></div>
                <div className="breadcrumb-lines breadcrumb-right"></div>
                <div className="container">
                    <div className="col-md-12 inner-breadcrum">
                        <h1>Samplework Projects</h1>
                        <p>Our Projects</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Breadcrumb;