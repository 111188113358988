import './Footer.css';

const Footer = () => {
    return(
        <>
            <footer className="footer-section section-bg  overflow-hidden pos-relative">
            <div className="footer-inner-shape-top-left"></div>
            <div className="footer-inner-shape-top-right"></div>
            <div className="footer-center section-gap-tb-165">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-n5">

                    <div className="col-auto mb-5">      
                        <h1 className='footer-logo text-light'>SAMPLEWORK</h1>  
                    </div>

                        <div className="col-auto mb-5">
                           
                            <div className="footer-single-info">
                                <a href="https://www.instagram.com/techvec/" className="info-box" target="_blank" rel="noreferrer">
                                    <span className="icon">
                                        <img src='./assets/images/instagram.svg' className='light-icon' alt='instagram'/></span>
                                    <span className="text">@techvec</span>
                                </a>
                            </div>
                           
                        </div>
                        <div className="col-auto mb-5">
                           
                            <div className="footer-single-info">
                                <a href="mailto:info@techvec.in" className="info-box" rel="noreferrer">
                                    <span className="icon"><img src="./assets/images/envelope-open-text.svg" className='light-icon' alt='mail'/></span>
                                    <span className="text">info@techvec.in</span>
                                </a>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row justify-content-center justify-content-md-between align-items-center flex-column-reverse flex-md-row">
                        <div className="col-auto copyright-text-samplework">
                            <div className="footer-copyright">
                                <p className="copyright-text">© 2022 Samplework.in</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
        </>
    );
}

export default Footer;