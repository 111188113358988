import './Project.css';

const Project = (props) => {
    
    return(
        
        <>
        <div className="col-md-6 filter-item html mb-5">
            <div className="project-box-single-item" id={`projectcard${props.id}`}>
                <div className="img-box">
                    <div className="bg-overlay"></div>
                    <div className="bg-image">
                        <img src={`./assets/images/${props.img}`} alt="project"/>
                    </div>
                    <div className="image">
                        <img src={`./assets/images/${props.img}`} alt="project"/>
                    </div>
                </div>
                <div className="content">
                    <h4 className="title"><a href={props.link} target="_blank" rel="noreferrer">{props.title}</a></h4>
                </div>
            </div>
        </div>
        </>
    );
}

export default Project;