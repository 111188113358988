import './App.css';
import Footer from './includes/Footer';
import Header from './includes/Header';
import Home from './pages/Home';

function App() {

  const projectData = [
    {
      'id' : '1',
      'img' : 'careplusdiagnostics2.webp',
      'title' : 'Care Plus Diagnostic',
      'link' : 'https://careplusdiagnostics.com/'
    },
    {
      'id': '2',
      'img': 'project-slider-img-1.webp',
      'title': 'Givest - PSD Template',
      'link' : '#'
    },
    {
      'id': '3',
      'img': 'project-slider-img-2.webp',
      'title': 'Musion - Gardening HTML Template',
      'link' : '#'
    },
    {
      'id': '4',
      'img': 'project-slider-img-3.webp',
      'title': 'SEOLLY - SEO Marketing & Digital Agency',
      'link' : '#'
    },
    {
      'id': '5',
      'img': 'project-slider-img-4.webp',
      'title': 'Virtuf - Creative Agency',
      'link' : '#'
    },
    {
      'id': '6',
      'img': 'project-slider-img-5.webp',
      'title': 'Tourve - Travel Agency',
      'link' : '#'
    },
    {
      'id': '7',
      'img': 'project-slider-img-6.webp',
      'title': 'Induste - Industrial & Factory',
      'link' : '#'
    },
    {
      'id': '8',
      'img': 'project-slider-img-7.webp',
      'title': 'Consor - Multi Purpose Website',
      'link' : '#'
    },
    {
      'id': '9',
      'img': 'project-slider-img-8.webp',
      'title': 'Asore - Business',
      'link' : '#'
    },
    
  ]

  return (
    <div className="App">
      <Header/>
       <Home projectData={projectData}/>
      <Footer/>
    </div>
  );
}

export default App;
