import React, {useEffect} from 'react';
import  './Header.css';

const Header = () => {

           // Sticky Menu Area
           useEffect(() => {
                window.addEventListener('scroll', isSticky);
                return () => {
                    window.removeEventListener('scroll', isSticky);
                };
            });
    
               
        /* Method that will fix header after a specific scrollable */
               const isSticky = (e) => {
                    const header = document.getElementById('header');
                    const scrollTop = window.scrollY;
                    scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
                };

    
    return(
        <>
            	<header className="header" id="header">
                    <div className="container">
                        <div className="inner-flex">
                            
                        <div className="logo_header">
                            <h1 className="trns"><a href="samplework.in" rel="noreferrer">SAMPLEWORK</a></h1>
                        </div>
                        <div className="nav_menu">
                            <div className="btn primary-btn">
                                <a href="https://techvec.in/contact" target="_blank" className="lencer-btn trns" rel="noreferrer">
                                    Hire Us <img src="./assets/images/chevrons-right.svg" className="light-icon header-button-icon" alt='arrow'/>
                                </a>
                            </div>
                        </div>
                            
                        </div>

                    </div>
                </header>
        </>
    );
}

export default Header;

